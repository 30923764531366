import { createLazyFileRoute } from "@tanstack/react-router";
import { MetalCreate } from "../../../modules/metal/MetalCreate.tsx";

export const Route = createLazyFileRoute("/_main/metal/create")({
    component: Component,
});

function Component() {
    return <MetalCreate />;
}
