import FF from "../../../components/forms/FormField.module.css";

import type { MetalOsId, MetalOsListResponse } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { entries, filter, groupBy, map, pipe } from "remeda";
import { Tile, TileSelect } from "../../../components/forms/TileSelect.tsx";
import { DataIcon } from "../../../components/icon/DataIcon.tsx";

export interface MetalOsSelectProps {
    data: MetalOsListResponse;

    value: MetalOsId | undefined;
    onChange: (os: MetalOsId) => void;

    ready_os_ids: MetalOsId[];
}

export function MetalOsSelect({ value, onChange, data: { os, os_types }, ready_os_ids }: MetalOsSelectProps) {
    const items = pipe(
        os,
        filter((o) => o.is_published),
        groupBy((o) => o.os_type_id),
        entries(),
        map(([key, values]) => {
            const osType = notNull(os_types.find((ot) => ot.os_type_id === key));
            const isSelected = value != null && values.some((o) => o.os_id === value);
            // When tile is selected, only display it as ready if the selected OS is ready
            // Otherwise, display it as ready if any of the OSes under it are ready
            const isReady = isSelected
                ? ready_os_ids.includes(value)
                : values.some((o) => ready_os_ids.includes(o.os_id));

            return {
                ...osType,
                isReady,
                osItems: values,
            };
        }),
    );

    const tiles = items.map(({ icon, os_type_id, title, osItems, isReady }) => (
        <Tile<MetalOsId>
            key={os_type_id}
            icon={() => <DataIcon data={icon} mode="mask" className="size-2rem" />}
            title={title}
            optionHeader="Version"
            items={osItems.map(({ version_title, os_id }) => ({
                key: os_id,
                value: os_id,
                label: version_title,
            }))}
        >
            {isReady && <div className={FF.Banner}>READY</div>}
        </Tile>
    ));

    return (
        <TileSelect<MetalOsId> value={value} equal={(a, b) => a === b} onChange={onChange}>
            {tiles}
        </TileSelect>
    );
}
