import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { MetalSpec, MetalSpecListItem } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { atomAllLocationsQuery } from "../location/atomAllLocationsQuery.ts";

export interface MetalSpecListItemLoc extends MetalSpecListItem {
    location: string;
}

export type Response = Map<MetalSpec["spec_id"], MetalSpecListItemLoc>;

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["metal/spec"] : ["metal/spec", params.location];
}

function specFromData(data: MetalSpecListItem, location: string): MetalSpecListItemLoc {
    return { ...data, location };
}

export interface Params {
    location: string;
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            return mapFromEntries(
                getResponseData(
                    await client.GET("/{location}/metal/specs", {
                        signal,
                        params: { path: { location } },
                    }),
                ),
                (spec) => [spec.spec_id, specFromData(spec, location)] as const,
            );
        },
    });
}

export const specLocationQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getQuery, { location }),
);

export const specQueryAtom = atomAllLocationsQuery(specLocationQueryAtom, (results) => mergeMaps(results));
