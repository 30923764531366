import type { MetalLeaseCreateBody, MetalOsId, MetalOsListResponse } from "@warrenio/api-spec/spec.oats.gen";
import { first } from "remeda";
import type { GetField, SetField } from "../../utils/getField.tsx";
import type { LocationsForType } from "../location/location.ts";
import type { LocationInputs } from "../location/LocationField.tsx";
import type { LocationSlug } from "../location/query.ts";
import type { MetalSpecListItemLoc } from "./metalSpecQuery.ts";

export interface MetalCreateInputs extends LocationInputs {
    display_name: MetalLeaseCreateBody["display_name"];
    requested_os_id: MetalLeaseCreateBody["requested_os_id"] | null;
    spec_uuid: MetalLeaseCreateBody["spec_uuid"] | null;
    billing_account_id: MetalLeaseCreateBody["billing_account_id"] | null;
}

export class MetalCreateViewModel {
    get: GetField<MetalCreateInputs> = (_n) => undefined;
    set: SetField<MetalCreateInputs> = (_n, _v) => {};

    constructor(
        // Necessary source data & configuration to validate values
        private readonly allImages: MetalOsListResponse,
        private readonly locations: LocationsForType,
        private readonly specs: MetalSpecListItemLoc[],
    ) {}

    attachGetterAndSetter(getter: typeof this.get, setter: typeof this.set) {
        this.get = getter;
        this.set = setter;
    }

    onChangeOs = (newValue: MetalOsId) => {
        console.debug("onChangeOs, value:", newValue);
        this.set("requested_os_id", newValue);
    };

    onLocationChange = (newValue: string) => {
        console.debug("onLocationChange, value:", newValue);
        this.set("location", newValue);
    };

    onSpecChange = (newValue: string) => {
        console.debug("onSpecChange, value:", newValue);
        this.set("spec_uuid", newValue);
    };

    //#region Default values
    private getDefaultLocation(): LocationSlug {
        return this.locations.defaultLocation;
    }

    private getDefaultOs() {
        return first(this.allImages.os)?.os_id ?? null;
    }

    private getDefaultSpec() {
        return this.specs.find((s) => s.is_available)?.uuid ?? null;
    }

    getDefaultValues(metal?: Partial<MetalCreateInputs>): MetalCreateInputs {
        console.debug("Getting form default values");
        return {
            display_name: "",
            location: this.getDefaultLocation(),
            requested_os_id: this.getDefaultOs(),
            spec_uuid: this.getDefaultSpec(),
            billing_account_id: null,
            ...metal,
        };
    }
    //#endregion Default values
}
